import React from "react"
import GetMarkDownData from "../components/resource/GetMarkDownData"

const resources = () => {
  return (
    <div>
      <GetMarkDownData />
    </div>
  )
}

export default resources
